<template>
<section class="services-all">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-5">
					<div class="content-serv content-serv-elearn" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="500">
						<h3> We Offer wide range of <br> e-Learning solutions </h3>
						<p> We transform your PowerPoint presentations, training videos, documents and a lot more into engaging SCORM web assets that your employees and product users can access on multiple devices. Our expertise spans across multiple technologies, authoring tools & content formats – enabling us to deliver solutions that are customized, adaptive, and localized. </p>
						
					</div>
				</div>
				<div class="col-lg-7">
					<div class="service-boxs" data-aos="zoom-in" data-aos-easing="ease-in-sine">
						<div class="row">
							<div class="col-md-4 mt-50">
								<div class="box-servic">
									<span class="icon-ser">
										<img src="@/assets/images/serv1.svg" class="icon-main" />
									</span>
									<h5> Instructional Designing </h5>
								</div>
								<!-- bgs-center -->
								<div class="box-servic">
									<span class="icon-ser icon2">
										<img src="@/assets/images/serv4.svg" class="icon-main" />
									</span>
									<h5> Multi-Device Solutions </h5>
								</div>
							</div>
							<div class="col-md-4">
								<div class="box-servic">
									<span class="icon-ser icon3">
										<img src="@/assets/images/game.png" class="icon-main icon-fs" />
									</span>
									<h5> Gamification and Simulation </h5>
								</div>
								<div class="box-servic">
									<span class="icon-ser icon4">
										<img src="@/assets/images/madcap.svg" class="icon-main icon-e" />
									</span>
									<h5> Madcap <br> Services </h5>
								</div>
							</div>
							<div class="col-md-4 mt-50">
								<div class="box-servic">
									<span class="icon-ser icon5">
										<img src="@/assets/images/serv3.svg" class="icon-main" />
									</span>
									<h5> LMS Design and Support</h5>
								</div>
								<div class="box-servic">
									<span class="icon-ser icon6">
										<img src="@/assets/images/serv6.svg" class="icon-main" />
									</span>
									<h5> Content Creation and Localization </h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    </template>
    <script>
export default {
};
</script>

	<!-- Testimonial Section Ends here -->
	