<template>
 <section class="how-it-works">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="title-testimonials">
						<h3> Our Process </h3>
					</div>
				</div>
			</div>
			<div class="row design-new-step">
				<span class="arrow-sters"> <img src="@/assets/images/lines.svg" />  </span>
				<div class="col">
					<div class="box-icns">
						<span class="icon-strs"> <img src="@/assets/images/icon1.png" /> </span>
						<p> Project Analysis </p>
					</div>
				</div>
				<div class="col">
					<div class="box-icns">
						<span class="icon-strs bg-blues"> <img src="@/assets/images/icon2.png" /> </span>
						<p> Content  Writing </p>
					</div>
				</div>
				<div class="col">
					<div class="box-icns">
						<span class="icon-strs bg-ora"> <img src="@/assets/images/icon3.png" /> </span>
						<p> Storyboarding</p>
					</div>
				</div>
				<div class="col">
					<div class="box-icns">
						<span class="icon-strs bg-purples"> <img src="@/assets/images/icon4.png" /> </span>
						<p> Course Design </p>
					</div>
				</div>
				<div class="col">
					<div class="box-icns">
						<span class="icon-strs bg-greens"> <img src="@/assets/images/icon5.png" /> </span>
						<p> Implementation </p>
					</div>
				</div>
			</div>
			
		</div>
	</section>
    </template>
    <script>
    import axios from "axios";
    export default {
      name: "App",
      components: {},
      data () {
        return {
     
        }
      },
      mounted() {
        this.getBanner();
    
      },
      methods: {
    
         getBanner() {
          this.loader3 = this.showSpinner();
          axios
            .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
              params: {
                post_type: "header_banner",
                taxonomy: "banner_type",
                cat_name: "home-page-banner",
                custom_link_field: "cta_button",
                custom_video_link: "video_url"
              }
            })
            .then(response => {
              this.loader3.hide();
              this.BannerContent = response.data.customPostList;
              console.log("BannerContent==", this.BannerContent);
            })
            .catch(error => {
              console.log("error", error);
            });
        },
        getIndex(i, len) {
          return i === (len - 1) ? 0 : i + 1
        },
        popupVideoModal() {
          document.getElementById('bnfrm').src = "https://www.youtube.com/embed/MmFWOU2cUPA";
          this.$refs.modal.open();
        },
        closePopup() {
          document.getElementById('bnfrm').src = "";
          this.$refs.modal.close();
          
        }
      }
    };
    </script>
    