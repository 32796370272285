var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"showcase"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('carousel-3d',{style:({ 'background-color': 'unset'}),attrs:{"width":600,"height":400,"controls-visible":true,"controls-width":30,"controls-height":60,"clickable":false,"display":3}},[_c('slide',{attrs:{"index":0}},[_c('img',{style:({'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}),attrs:{"src":require("@/assets/images/work5.png")}})]),_c('slide',{attrs:{"index":1}},[_c('img',{style:({'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}),attrs:{"src":require("@/assets/images/work1.png")}})]),_c('slide',{attrs:{"index":2}},[_c('img',{style:({'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}),attrs:{"src":require("@/assets/images/work2.png")}})]),_c('slide',{attrs:{"index":3}},[_c('img',{style:({'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}),attrs:{"src":require("@/assets/images/work3.png")}})]),_c('slide',{attrs:{"index":4}},[_c('img',{style:({'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}),attrs:{"src":require("@/assets/images/work4.png")}})])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"title-testimonials"},[_c('h3',[_vm._v(" Work Showcase ")])])])
}]

export { render, staticRenderFns }