<template>
<section class="showcase">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="title-testimonials">
					<h3> Work Showcase </h3>
				</div>
			</div>
	
        <carousel-3d v-bind:style="{ 'background-color': 'unset'}" :width="600" :height="400" :controls-visible="true" 
               :controls-width="30" :controls-height="60" :clickable="false" :display="3">
  <slide :index="0">
    <img  v-bind:style="{'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}" src="@/assets/images/work5.png" />

</slide>
  <slide :index="1">
    <img v-bind:style="{'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}" src="@/assets/images/work1.png" />

</slide>
  <slide :index="2">
    <img v-bind:style="{'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}" src="@/assets/images/work2.png" />
 
</slide>
  <slide :index="3">
    <img v-bind:style="{'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}" src="@/assets/images/work3.png" />

</slide>
  <slide :index="4">
    <img v-bind:style="{'display': 'block','box-shadow': '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    'border-radius': '4px', 'width': '100%','height': '400px','background': '#fff'}" src="@/assets/images/work4.png" />
 
</slide>
</carousel-3d>
        </div>
        </div>
		
</section>
  </template>
  <script>
  import Vue from 'vue';
  import { Carousel3d, Slide } from 'vue-carousel-3d';
  
  Vue.use(Carousel3d);
  export default {
    components: {
      Carousel3d,
      Slide
    }
  };
  </script>