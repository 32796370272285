<template>
<Section class="banner-section banner-elearn">
		<div class="inner-conts">
      <div v-html="mainHeading"></div>
			<div class="banner-btns">
				<a href="/get-a-quote" class="btn-orange">  Let's Get Started  </a>
			</div>
		</div>
	</Section>
</template>
<script>

import axios from "axios";
var mainHeading="";
export default {
  name: "App",
  components: {},
  data () {
    return {
      partners: [],
      mainHeading:mainHeading,
    }
  },
  mounted() {
    this.getBanner();

  },
  methods: {

     getBanner() {
      this.loader3 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "header_banner",
            taxonomy: "banner_type",
            cat_name: "e-learning-page-banner",
            custom_link_field: "cta_button",
            custom_video_link: "video_url",
            main_heading: "main_heading"
          }
        })
        .then(response => {
          this.loader3.hide();
       
          this.BannerContent = response.data.customPostList;
         mainHeading=this.BannerContent[0].main_heading;
         this.mainHeading= mainHeading;
          console.log("BannerContentElearning==", this.BannerContent);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getIndex(i, len) {
      return i === (len - 1) ? 0 : i + 1
    },
  }
};
</script>
