<template>
<section class="resources">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-testimonials">
                    <h3>Resources</h3>
                </div>
                <div class="tabs-resource" data-aos="fade-up" data-aos-duration="2000">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Blog</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Datasheet</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="Case-tab" data-toggle="tab" data-target="#Case-tab-pane" type="button" role="tab" aria-controls="Case-tab-pane" aria-selected="false">Case Study</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                            <div class="blog-data">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="blog-box">
                                            <div class="img-blog">
                                                <img src="@/assets/images/blog1.png" />
                                            </div>
                                            <div class="cont-user">
                                                <h4>Top 5 eLearning Trends to Watch Out for in 2021</h4>
                                                <a href="https://www.grazitti.com/blog/top-5-elearning-trends-to-watch-out-for-in-2021/" target="_blank" class="read-more">
                                                    Read More
                                                    <span class="arrow-rigt"> <img src="@/assets/images/arrow.svg" /> </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                            <div class="datasheet-data">
                                <div class="row align-items-center">
                                    <div class="col-md-6">
                                        <div class="data-img">
                                            <img src="@/assets/images/datasheet.png" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="datasheet-cont">
                                            <h5 class="sbheadr"><span> Datasheet </span></h5>
                                            <h3>Phenomenal Storytelling Drives Excellent Results</h3>
                                            <p>Know more about our engaging videos that increase engagement levels leading to more conversions.</p>
                                            <a target="_blank" href="https://www.grazitti.com/assets/2019/06/Phenomenal-Storytelling-Drives-Excellent-Results-latest.pdf" class="btn-orange">
                                                <i class="fa fa-download" aria-hidden="true"></i> Download
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="Case-tab-pane" role="tabpanel" aria-labelledby="Case-tab" tabindex="0">
                            <div class="datasheet-data">
                                <div class="row align-items-center">
                                    <div class="col-md-6">
                                        <div class="data-img">
                                            <img src="@/assets/images/case.png" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="datasheet-cont">
                                            <h5 class="sbheadr"><span> Case Studies </span></h5>
                                            <h3>LMS-Based Training Video Modules</h3>
                                            <p>Have a look at bringing down the customer's support costs, while boosting customer engagement levels.</p>
                                            <a target="_blank" href="https://pages.grazitti.com/de-cs-lms-based-training-video-modules.html?utm_source=website&utm_medium=website&utm_campaign=video" class="btn-orange">
                                                <i class="fa fa-download" aria-hidden="true"></i> Download
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

</template>
<script>

  export default {
  };
</script>
  