<template>
<section class="bg-striped">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="stripd-conte">
						<h4> Ready to Discuss a Project? </h4>
						<a href="/get-a-quote" class="btn-orange"> Get a Quote </a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
  <script>
export default {
};
</script>

